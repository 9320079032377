<div id="homePortfolio">
    <div class="container">
        <div id="homePortfolioContent" class="col-md-12 ">
            <h5 id="homePortfolioTitle" class="color02  ">Portfolio</h5>
            <h2 id="homePortfolioSubTitle" class="color01  ">作品集</h2>
            <div id="homePortfolioTitleHr"></div>
        </div>
        <div class="card-columns ">
            <div class="card rounded-0 shadow-sm" *ngFor="let portfolio of portfolios">
                <a routerLink="/portfolio" (mouseup)="refresh()" style="color: #767676;">
                    <div class="imgContainer" style="position: relative;overflow: hidden;">
                        <img class="card-img-top rounded-0 img-fluid" src={{portfolio.url}} alt="Card image cap"
                            style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
                    </div>
                    <div class="card-body">
                        <p class="card-text" style="font-weight: 600;">{{portfolio.name}}</p>
                    </div>
                </a>
            </div>

        </div>
        <!-- <a routerLink="/portfolio" (mouseup)="refresh()"><button id="homePortfolioButton"
                class="btn rounded-0">瀏覽更多</button></a> -->
                <button class="btn rounded-0" id="homePortfolioButton" (click)="openLink()">看更多</button>

    </div>
</div>