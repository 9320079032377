<ul class="nav border w-100 px-1" style="margin-bottom:2rem;display: flex;justify-content: center;">
    <li class="nav-item" *ngFor="let cover of portfolioCover">
        <a class="nav-link">{{cover.name}}</a>
    </li>
</ul>
<!-- <div class="row "> -->
<!-- <div class=" col-lg-4 albumCover" *ngFor="let cover of portfolioCover">
        <a routerLink="/home">
            <div [ngStyle]="{'background-image':'url(' + cover.img + ')'}"
                style="background-size: cover;background-position: center;background-repeat: no-repeat;padding-top: 60%;">
            </div>
            <p class="imageDes" style="color: #767676;font-weight: bold;">{{cover.name}}</p>
        </a>
    </div> -->

<!-- </div> -->
<!-- <div class='embedsocial-album' data-ref="24e908c91744b8538dfa0460f1c8d1ff60823c9f"></div> -->