<div id="footerContainer" style="position: relative;margin-top: 18rem;">
    <div id="reservePhoto" class="  row bg-white shadow-sm"
        style="left: 50%;transform:translate(-50%,-8rem);width: 82%;margin: auto;padding: 2rem;position: absolute;">
        <div class=" col-lg-4">
            <h3 id="reservePhotoTitle" style="color: #bf925c;font-size: 1.6rem;margin-bottom: 1.5rem;">預
                約 拍 攝</h3>
            <div class="reservePhotoHr"
                style="border: 0.06rem solid #e3c7a4;background-color: #b18247;width: 4rem;margin-top: 0.6rem;margin-bottom: 2rem;">
            </div>
        </div>
        <div class=" col-lg-8">
            <p style="color: #767676;font-weight: 100;margin-bottom: 2rem;">Email：<span
                    style="text-decoration: underline;">pm6886baby@gmail.com</span></p>
            <div class=" row">
                <div class=" col-xl-2" style="color: #4f4f4f;line-height: 3rem;">Facebook</div>
                <div class=" col-xl-5" style="color: #4f4f4f;font-size: 0.8rem;line-height: 3rem;">
                    <div class=""
                        style="width: 34px;height:34px;display:inline-block;background-image: url(assets/images/icon/messenger.png);vertical-align: middle;background-repeat: no-repeat;">
                    </div>
                    <a style="color: #4f4f4f;" href="https://www.facebook.com/PMphotomakeup">PM Baby 新生兒寫真</a>
                </div>
                <div class=" col-xl-5" style="color: #4f4f4f;font-size: 0.8rem;line-height: 3rem;">
                    <div class=""
                        style="width: 34px;height:34px;display:inline-block;background-image: url(assets/images/icon/messenger.png);vertical-align: middle;background-repeat: no-repeat;">
                    </div>
                    <a style="color: #4f4f4f;" href="https://www.facebook.com/PMstudiomakeup"> Studio 婚紗攝影</a>
                </div>
            </div>
        </div>
    </div>

    <div class="row align-items-start"
        style="padding-top:7rem;background-color: #f2f2f2;margin-left: 0;margin-right: 0;">
        <div id="facebookLink" class=" col-md-4 color02 " style="background-color: #F2F2F2;font-size: 0.8rem;">
            <div>
                <div
                    style="width: 34px;height:34px;background-image: url(assets/images/icon/facebook.png);vertical-align: middle;background-repeat: no-repeat;display: inline-block;">
                </div>
                <span
                    style="height: 1.7rem;padding-left: 0.3rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;">Facebook
                    粉絲團
                </span>
            </div>
            <div class=" commumityLink" style="padding-left: 34px;">
                <a href="https://www.facebook.com/PMphotomakeup" class="color02 "
                    style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                    Baby 新生兒寫真 </a>
                <a href="https://www.facebook.com/PMstudiomakeup" class="color02 "
                    style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                    Studio 婚紗攝影 </a>
            </div>
        </div>
        <div id="igLink" class=" col-md-4 color02 " style="background-color: #F2F2F2;font-size: 0.8rem;">
            <div>
                <div class=""
                    style="width: 34px;height:34px;background-image: url(assets/images/icon/instagram.png);vertical-align: middle;background-repeat: no-repeat;display: inline-block;">
                </div>
                <span class=""
                    style="height: 1.7rem;vertical-align: middle;padding-left: 0.3rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;">instagram
                </span>
            </div>
            <div class=" commumityLink" style="padding-left: 34px;">
                <a href="https://www.instagram.com/newbornpm/" class="color02 "
                    style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                    Baby 新生兒寫真 </a>
                <a href="https://www.instagram.com/weddingphoto_pmstudio/" class="color02 "
                    style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                    Studio 婚紗攝影 </a>
            </div>
        </div>
        <div id="googleLink" class=" col-md-4 color02 " style="background-color: #F2F2F2;font-size: 0.8rem;">
            <div>
                <div
                    style="width: 34px;height:34px;background-image: url(assets/images/icon/icon-googlemap.png);vertical-align: middle;background-repeat: no-repeat;display: inline-block;">
                </div>
                <span
                    style="height: 1.7rem;vertical-align: middle;padding-left: 0.3rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;">Google
                    Map
                </span>
            </div>
            <div class=" commumityLink " style="padding-left:34px;">
                <a href="https://g.page/PMphotomakeup?share" class="color02 "
                    style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">104台北市中山區民權東路三段69號2樓 </a>
            </div>
        </div>
        <div id="copyRight" class=" col-lg-12"
            style="background-color: #F2F2F2;text-align: center;position: relative;padding-top: 4rem;;">
            <span class="color02"
                style="font-size: 0.6rem;position: absolute;bottom: 32%;left: 50%;transform: translate(-50%);white-space:nowrap;">Copyright
                © PM
                Studio All Rights
                Reserved.</span>
        </div>
    </div>
</div>