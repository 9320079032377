<div class="banner mb-5" style="margin-top: 4rem;">
    <img class="img-fluid" src="assets/images/service/banner/services-banner.png" alt="" style="width: 100%;">
</div>
<div class=" container">
    <!-- <ul class="nav " style="margin-bottom:2rem;">
        <li class="nav-item">
            <a class="nav-link active" href="#ok">新生兒寫真</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#ok">兒童寫真</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#">全家福</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " href="#">孕婦寫真</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " href="#">專業形象照</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " href="#">個人藝術照</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " href="#">婚紗攝影</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " href="#">週歲派對攝影紀錄</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " href="#">婚禮紀錄</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " href="#">注意事項</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " href="#">合作禮服廠商</a>
        </li>
    </ul> -->
    <ul class="nav " style="margin-bottom:2rem;display: flex;justify-content: center;">
        <li class="nav-item" *ngFor="let service of services">
            <a [routerLink]='"."' [fragment]="service.name" class="nav-link">{{service.name}}</a>
        </li>
    </ul>
    <hr class="underNavHr" style="margin-bottom: 4rem;">
    <div class="row" *ngFor="let service of services" style="margin-bottom: 2rem;">
        <h5 [attr.id]='service.name' class=" serviceTitle" style="color: #b18247;width: 100%;">{{service.name}}</h5>
        <div class=" col-lg-6 order-2">
            <p style="color:#989898 ;font-size: 0.8rem;">{{service.price}}</p>
            <ul>
                <li *ngFor="let list of service.list01"
                    style="list-style-type: circle;font-size: 0.8rem;color: #767676;">{{list}}</li>
            </ul>
            <p style="color: #989898;font-size: 0.7rem;width: 70%;margin-bottom: 1.5rem;">
                {{service.note}}</p>
            <p style="color: #767676;font-size: 0.8rem;">{{service.plusItem}}</p>
            <ul>
                <li *ngFor="let list of service.list02" style="font-size: 0.8rem;color: #767676;">{{list}}</li>
            </ul>
        </div>
        <div class="col-lg-6 order-1" style="text-align: center;">
            <img src={{service.img}} alt="" class=" img-fluid" style="width: 70%;">
        </div>
        <hr class="order-3" style="background-color: #e3c7a4;width: 30%;margin-top: 2rem;">
    </div>
    <div id="notification" class=" p-4" style="background-color: #fcf2e6;width: 100%;margin-bottom: 5rem;">
        <h3 class="title" style="color:#b18247;font-size:x-large">注 意 事 項</h3>
        <ul>
            <li style="list-style: none;color: #767676;font-size: 0.8rem;">假日拍攝需加價開棚費 $1,000</li>
            <li style="list-style: none;color: #767676;font-size: 0.8rem;">若因不可控因素延期拍攝，將不產生額外費用；請儘早通知團隊，並另約拍攝日期</li>
            <li style="list-style: none;color: #767676;font-size: 0.8rem;">各方案將於訂金確認到帳後，才算正式進入拍攝排程</li>
            <li style="list-style: none;color: #767676;font-size: 0.8rem;">訂金皆為 $3,000，若需取消拍攝，酌收工本費 $1,000，其餘費用退還</li>
            <li style="list-style: none;color: #767676;font-size: 0.8rem;">若因不可控因素延期拍攝，將不產生額外費用；請儘早通知團隊，並另約拍攝日期</li>
        </ul>
    </div>

    <div class="row">
        <h3 class="title" style="color:#b18247;font-size:x-large;text-align: center;width: 100%;">合 作 禮 服 廠 商</h3>
        <div class=" col-lg-4 col-6 p-1">
            <img class="img-fluid"
                src="assets/images/service/servicePhoto/dressSupplier/services-demetrios-logo_2021-02-17/services-demetrios-logo@2x.png"
                alt="">
        </div>
        <div class=" col-lg-4 col-6 p-1">
            <img class="img-fluid"
                src="assets/images/service/servicePhoto/dressSupplier/services-hm-logo_2021-02-17/services-hm-logo@2x.png"
                alt="">
        </div>
        <div class=" col-lg-4 col-6 p-1">
            <img class="img-fluid"
                src="assets/images/service/servicePhoto/dressSupplier/services-shhsboutioue-logo_2021-02-17/services-shhsboutioue-logo@2x.png"
                alt="">
        </div>
        <div class=" col-lg-4 col-6 p-1">
            <img class="img-fluid"
                src="assets/images/service/servicePhoto/dressSupplier/services-eas-logo_2021-02-17/services-eas-logo@2x.png"
                alt="">
        </div>
        <div class=" col-lg-4 col-6 p-1">
            <img class="img-fluid"
                src="assets/images/service/servicePhoto/dressSupplier/services-cl-logo_2021-02-17/services-cl-logo@2x.png"
                alt="">
        </div>
    </div>
</div>
<app-footer></app-footer>