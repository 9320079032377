<div id="homeContact" class="pb-0">
    <div class="container">
        <div class="row ">
            <div id="homeContactContent" class="col-md-12 ">
                <h5 id="homeContactTitle" class="color02  ">Contact</h5>
                <h2 id="homeContactSubTitle" class="color01  ">聯絡我們</h2>
                <div id="homeContactTitleHr"></div>
            </div>
            <div id="contactMap" class="borer w-100" style="padding-top: 53%;position: relative;overflow: hidden;">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.131013234008!2d121.54144442737228!3d25.062693605221344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a90557fb2d2d%3A0xff4c363c483f0bfb!2zUE0gQmFieSDmlJ3lvbEg5paw55Sf5YWS5a-r55yfLOWFkuerpeWvq-ecnyzlhajlrrbnpo8s5a2V5amm5a-r55yf!5e0!3m2!1szh-TW!2stw!4v1643772301439!5m2!1szh-TW!2stw" width="230%" height="850" frameborder="0"
                style="border:0;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);"
                allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                <!-- <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.223369024937!2d121.52024031494504!3d25.060417283960028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a90557fb2d2d%3A0xff4c363c483f0bfb!2zUE0gQmFieSDmlJ3lvbEg5paw55Sf5YWS5a-r55yfLOWFkuerpeWvq-ecnyzlhajlrrbnpo8s5a2V5amm5a-r55yf!5e0!3m2!1szh-TW!2stw!4v1611447752504!5m2!1szh-TW!2stw"
                    width="230%" height="850" frameborder="0"
                    style="border:0;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);"
                    allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->
            </div>
            <div id="contactLeft" class=" col-md-6">
                <div class="  color02 mb-3" style="font-size: 0.8rem;">
                    <!-- <span style="line-height: 1.6rem;">地址：104 台北市中山區錦西街 1-3 號 3 樓</span><br> -->
                    <span style="line-height: 1.6rem;">地址：104台北市中山區民權東路三段69號2樓</span><br>
                    <span style="line-height: 1.6rem;">營業時間 : 10:00–19:30</span><br>
                    <span style="line-height: 1.6rem;">Email：pm6886baby@gmail.com</span>
                </div>
                <div class="  color02" style="font-size: 0.8rem;display: flex;">
                    <div class="">
                        <div class=""
                            style="width: 34px;height:34px;display:inline-block;background-image: url(assets/images/icon/messenger.png);vertical-align: middle;background-repeat: no-repeat;">
                        </div>
                    </div>
                    <!--  -->
                    <div class="">
                        <span class=""
                            style="height: 34px;vertical-align: middle;padding-left: 0.3rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;">FB
                            Messengers :
                        </span>
                        <a href="https://www.facebook.com/PMphotomakeup" class="color02 "
                            style="height: 34px;padding-left: 0.4rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;text-decoration: underline;">PM
                            Baby 新生兒寫真
                        </a>
                        <a href="https://www.facebook.com/PMstudiomakeup" class="color02 "
                            style="height: 34px;padding-left: 0.4rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;text-decoration: underline;">PM
                            Studio
                            婚紗攝影</a>
                    </div>
                </div>
            </div>
            <div id="contactRight" class=" col-md-6" style="font-size: 0.8rem;">
                <div class=" color02">
                    <span class="color01" style="line-height: 1.6rem;">大眾交通工具</span><br>
                    <span style="line-height: 1.4rem;">大眾交通工具
                        捷運：中山國中站出來後，沿復興北路往北，民權東路三段左轉步行2分鐘即可抵達。
                    </span><br>
                </div>
                <div id="drive" class=" color02" style="margin-top: 1.5rem;">
                    <span class="color01" style="line-height: 1.6rem;">開車</span><br>
                    <span style="line-height: 1.4rem;">沿國道一號前往台北。從國道一號的 23A-圓山 號出口下交流道，走復興北路地下道和復興北路前往民權東路三段。
                    </span><br>
                </div>
            </div>
            <!-- <div id="facebookLink" class=" col-lg-4 col-md-6 color02"
                style="background-color: #F2F2F2;font-size: 0.8rem;display: flex;">
                <div class="">
                    <div class=""
                        style="width: 34px;height:34px;background-image: url(assets/images/icon/facebook.png);vertical-align: middle;background-repeat: no-repeat;">
                    </div>

                </div>
                <div class=" commumityLink">
                    <span class=""
                        style="height: 1.7rem;vertical-align: middle;padding-left: 0.3rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;">Facebook
                        粉絲團
                    </span><br>
                    <a href="#" class="color02 "
                        style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                        Baby 新生兒寫真 </a>
                    <a href="#" class="color02 "
                        style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                        Studio 婚紗攝影 </a>
                </div>
            </div>
            <div id="igLink" class=" col-lg-4 col-md-6 color02"
                style="background-color: #F2F2F2;font-size: 0.8rem;display: flex;">
                <div class="">
                    <div class=""
                        style="width: 34px;height:34px;background-image: url(assets/images/icon/instagram.png);vertical-align: middle;background-repeat: no-repeat;">
                    </div>
                </div>
                <div class=" commumityLink">
                    <span class=""
                        style="height: 1.7rem;vertical-align: middle;padding-left: 0.3rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;">instagram
                    </span><br>
                    <a href="#" class="color02 "
                        style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                        Baby 新生兒寫真 </a>
                    <a href="#" class="color02 "
                        style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                        Studio 婚紗攝影 </a>
                </div>
            </div>
            <div id="copyRight" class=" col-lg-4 col-md-12"
                style="background-color: #F2F2F2;text-align: center;position: relative;padding-top: 4rem;;">
                <span class="color02"
                    style="font-size: 0.6rem;position: absolute;bottom: 32%;left: 50%;transform: translate(-50%);white-space:nowrap;">Copyright
                    © PM
                    Studio All Rights
                    Reserved.</span>
            </div> -->
        </div>
    </div>
</div>
<div class="constainer-fluid border px-2">
    <div class="row border">
        <div id="facebookLink" class=" col-lg-4 col-md-6 color02"
            style="background-color: #F2F2F2;font-size: 0.8rem;display: flex;">
            <div class="">
                <div class=""
                    style="width: 34px;height:34px;background-image: url(assets/images/icon/facebook.png);vertical-align: middle;background-repeat: no-repeat;">
                </div>

            </div>
            <div class=" commumityLink">
                <span class=""
                    style="height: 1.7rem;vertical-align: middle;padding-left: 0.3rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;">Facebook
                    粉絲團
                </span><br>
                <a href="https://www.facebook.com/PMphotomakeup" class="color02 "
                    style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                    Baby 新生兒寫真 </a>
                <a href="https://www.facebook.com/PMstudiomakeup" class="color02 "
                    style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                    Studio 婚紗攝影 </a>
            </div>
        </div>
        <div id="igLink" class=" col-lg-4 col-md-6 color02"
            style="background-color: #F2F2F2;font-size: 0.8rem;display: flex;">
            <div class="">
                <div class=""
                    style="width: 34px;height:34px;background-image: url(assets/images/icon/instagram.png);vertical-align: middle;background-repeat: no-repeat;">
                </div>
            </div>
            <div class=" commumityLink">
                <span class=""
                    style="height: 1.7rem;vertical-align: middle;padding-left: 0.3rem;display: inline-block;vertical-align: middle;padding-top: 0.25rem;">instagram
                </span><br>
                <a href="https://www.instagram.com/newbornpm/" class="color02 "
                    style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                    Baby 新生兒寫真 </a>
                <a href="https://www.instagram.com/weddingphoto_pmstudio/" class="color02 "
                    style="line-height: 1.7rem;padding-left: 0.4rem;display: inline-block;vertical-align: middle;text-decoration: underline;font-size: 0.8rem;">PM
                    Studio 婚紗攝影 </a>
            </div>
        </div>
        <div id="copyRight" class=" col-lg-4 col-md-12"
            style="background-color: #F2F2F2;text-align: center;position: relative;padding-top: 4rem;;">
            <span class="color02"
                style="font-size: 0.6rem;position: absolute;bottom: 32%;left: 50%;transform: translate(-50%);white-space:nowrap;">Copyright
                © PM
                Studio All Rights
                Reserved.</span>
        </div>
    </div>
</div>