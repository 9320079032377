<div class="banner mb-5" style="margin-top: 4rem;">
    <img class="img-fluid" src="assets/images/portfolio/banner/portfolio-banner.jpg" alt="" style="width: 100%;">
</div>
<div class=" container ">
    <ul class="nav w-100 px-1" style="margin-bottom:2rem;display: flex;justify-content: center;">
        <li class="nav-item" *ngFor="let cover of portfolioCover">
            <!-- <a routerLink='/portfolio/new-born' class="nav-link">{{cover.name}}</a> -->
            <a class="nav-link" (click)="showTabContent(cover.name)" style="cursor: pointer;">{{cover.name}}</a>
        </li>
    </ul>


    <!-- <router-outlet></router-outlet> -->
    <app-portfolio-new-born [hidden]='tab!="新生兒寫真"'></app-portfolio-new-born>
    <app-portfolio-family [hidden]='tab!="全家福"'></app-portfolio-family>
    <app-portfolio-pregnant [hidden]='tab!="孕媽咪寫真"'></app-portfolio-pregnant>
    <app-portfolio-wedding [hidden]='tab!="婚紗"'></app-portfolio-wedding>
    <app-portfolio-wedding2 [hidden]='tab!="仙人掌婚禮"'></app-portfolio-wedding2>
    <app-portfolio-weddingrecord [hidden]='tab!="婚禮紀錄"'></app-portfolio-weddingrecord>
    <app-portfolio-art [hidden]='tab!="藝術照"'></app-portfolio-art>
    <app-portfolio-pmalbum [hidden]='tab!="PM相本"'></app-portfolio-pmalbum>

</div>
<app-footer></app-footer>