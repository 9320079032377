<div id="homeService" class="bgcolor">
    <div class="container">
        <div class="row ">
            <div id="homeServiceContent01" class="col-md-6 ">
                <h5 id="homeServiceTitle" class="color02  ">Services</h5>
                <h2 id="homeServiceSubTitle" class="color01  ">服務項目</h2>
                <div id="homeServiceTitleHr"></div>
            </div>
            <div id="homeServiceContent02" class="col-md-6 ">
                <div id="homeServiceImage" class="">
                    <img src="assets/images/homeService/119518370-957582317987213-153864102955016469-o_2021-01-15/119518370-957582317987213-153864102955016469-o@2x.png"
                        class="img-fluid" alt="">
                </div>
            </div>
            <div *ngif="false" id="homeServiceContent03" class="col-md-6 ">
                <p id="homeServiceArticle" class="color02 ">
                    婚紗攝影、孕婦寫真、新生兒攝影，一直到全家福，請點選服務項目，尋找最適合您的攝影方案，人生的各個重要時刻，我們都已經準備好，為您仔細紀錄每一瞬間。</p>
                <a routerLink="/service"><button id="homeServiceButton" class="btn rounded-0">方案內容</button></a>
            </div>
        </div>
    </div>
</div>