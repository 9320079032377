<div id="brandStoryContainer" class=" " style="margin: 0 auto 5rem;">
    <div class=" banner" style="margin-top: 4rem;">
        <img class="img-fluid" src="assets/images/aboutUsBanner/about-banner.png" alt="" style="width: 100%;">
    </div>
    <div class=" col-md-6 brandStoryLogo" style="margin: 6rem auto 1rem; ">
        <img class="img-fluid " style="display: block;" src="assets/images/aboutUsBanner/img-pm-studio-about@2x.png"
            alt="">


        <p class=" brandStory"
            style="font-size: 1.6rem;color: #bf925c;font-weight: 550;text-align: center;margin-top: -1.8rem;">
            品 牌 故 事
        </p>
    </div>
    <div class=" col-md-6 brandStoryContent" style="margin: auto;">
        <p style="text-align: center;color: #4f4f4f;font-size: 0.9rem;margin-bottom: 0.2rem;">
            孩子的成長過程稍縱即逝，父母對孩子的愛卻是鍾愛一生</p>
        <p style="text-align: center;color: #4f4f4f;font-size: 0.9rem;margin-bottom: 2.3rem;">
            一舉手、一投足，都想深印在腦海裡</p>
        <p style="text-align: center;color: #4f4f4f;font-size: 0.9rem;margin-bottom: 0.2rem;">
            這一刻，請把最幸福的畫面交給我們！每按下一個快門，</p>
        <p style="text-align: center;color: #4f4f4f;font-size: 0.9rem;margin-bottom: 2.3rem;">
            都是我們滿滿的祝福，為您紀錄人生中最值得珍藏的回憶！</p>
        <p style="text-align: center;color: #4f4f4f;font-size: 0.9rem;margin-bottom: 0.2rem;">
            人生的各種經歷，見證兩人的愛情故事，初次為人父母的喜悅，</p>
        <p style="text-align: center;color: #4f4f4f;font-size: 0.9rem;margin-bottom: 2.3rem;">
            我們都想一同參與。
        </p>
        <p style="text-align: center;color: #4f4f4f;font-size: 0.9rem;margin-bottom: 0.2rem;">
            為您留下各種紀念，未來翻開紀念的回憶冊，仍然能感受到那份美好。
        </p>
        <p style="text-align: center;color: #4f4f4f;font-size: 0.9rem;margin-bottom: 2.3rem;">
            PM團隊以真摯熱誠的心，為您紀錄專屬回憶。成就無價的幸福點滴。
        </p>
    </div>
</div>
<div id="aboutUsTitleHr" style="border: 0.1rem solid #e3c7a4;
background-color: #b18247;
width: 5.5rem;
margin-top: 1rem;
margin: auto;
margin-bottom: 5rem;"></div>

<div class=" col-md-10" style="margin: auto;">
    <h3 id="teamMemberTitle" class="" style="color: #bf925c;font-size: 1.6rem;margin-bottom: 2rem;">團 隊 經 歷 & 理 念</h3>
    <!-- <h3 id="teamMemberTitle" class="" style="color: #bf925c;font-size: 1.6rem;margin-bottom: 2rem;">團 隊 成 員</h3> -->
    <!-- <div class=" row" style="margin-bottom: 4rem;">
        <div class=" col-lg-5" style="text-align: center;">
            <img src="assets/images/aboutUsMember/memberPic.jpg" alt="" class=" img-fluid">
        </div>
        <div class=" col-lg-7 px-4">
            <h4 style="color: #b18247; font-size: 1.2rem;padding-top: 1rem;">堡鹿</h4>
            <p style="color: #4f4f4f;font-size: 0.8rem;margin-bottom: 1rem;">首 席 攝 影 師</p>
            <p style="color: #989898;font-size: 1rem;margin-bottom: 0.8rem;">理念</p>
            <p style="color: #767676;font-size: 0.8rem;margin-bottom: 2rem;">
                永遠以客戶需求為出發、可以幫您將影像魅力發揮到極致的攝影師；擁有超過10年的攝影美術經驗，提供廣告、商品、雜誌及活動拍攝；作品涵蓋各大領域，至今仍致力於創造優美且感動人心的影像作品。</p>
            <p style="color: #989898;font-size: 1rem;margin-bottom: 0.8rem;">經歷</p>
            <p style="color: #767676;font-size: 0.8rem;margin-bottom: 2rem;line-height: 1.3rem;">
                -中華航空BIE遊泰國際國平面廣告攝影<br>
                -中華航空飛機精品型錄攝影<br>
                -台灣虎航新加坡航線宣傳照攝影<br>
                -台灣虎航飛機精品型錄攝影<br>
                -台灣虎航飛機菜單型錄攝影<br>
                -天下雜誌廣編特輯人物專訪攝影<br>
                -商業週刊廣編特輯人物專訪攝影</p>
        </div>
    </div>
    <div class=" row" style="margin-bottom: 5rem;">
        <div class=" col-lg-5" style="text-align: center;">
            <img src="assets/images/aboutUsMember/memberPic.jpg" alt="" class=" img-fluid">
        </div>
        <div class=" col-lg-7 px-4">
            <h4 style="color: #b18247; font-size: 1.2rem;padding-top: 1rem;">妹倫 A-mei</h4>
            <p style="color: #4f4f4f;font-size: 0.8rem;margin-bottom: 1rem;">資 深 造 型 師</p>
            <p style="color: #989898;font-size: 1rem;margin-bottom: 0.8rem;">理念</p>
            <p style="color: #767676;font-size: 0.8rem;margin-bottom: 2rem;">
                永遠以客戶需求為出發、可以幫您將影像魅力發揮到極致的攝影師；擁有超過10年的攝影美術經驗，提供廣告、商品、雜誌及活動拍攝；作品涵蓋各大領域，至今仍致力於創造優美且感動人心的影像作品。</p>
            <p style="color: #989898;font-size: 1rem;margin-bottom: 0.8rem;">經歷</p>
            <p style="color: #767676;font-size: 0.8rem;margin-bottom: 2rem;line-height: 1.3rem;">
                -中華航空BIE遊泰國際國平面廣告攝影<br>
                -中華航空飛機精品型錄攝影<br>
                -台灣虎航新加坡航線宣傳照攝影<br>
                -台灣虎航飛機精品型錄攝影<br>
                -台灣虎航飛機菜單型錄攝影<br>
                -天下雜誌廣編特輯人物專訪攝影<br>
                -商業週刊廣編特輯人物專訪攝影</p>
        </div>
    </div> -->
    <p style="color: #767676;font-size: 1rem;margin-bottom: 2rem;width: 90%;margin: 0 auto;">
        從創辦以來,已有數品牌月子照護中心及知名人士與PM baby合作,透過專業攝影相關技術與進修嬰兒照護知識,提升攝影業也能滿足服務理念,紀錄初生的美好
        近年整合PM baby(新生兒攝影)&PM Studio(婚紗人像攝影)<br><br>
        品牌風格清晰化,持續為影像注入當代溫度,更希望藉由攝影,創意及造型的力量,堅持美感及藝術
        實現"愛"的影像瞬間,創造生命的回憶,回憶永久留存<br><br>


        <span style="color:#bf925c">攝影&造型團隊</span><br>
        從事人像攝影工作20年, 我們擅於人物,形象、新生兒、婚紗、婚禮，和家庭親子寫真<br><br>

        <span style="color:#bf925c">目標與困難及啟示</span><br>
        攝影產業並非開膜後能做上千萬相同的產品,現今流行app攝影修圖,困難可貴的新生兒攝影,充滿溫度的作品渲染,透過相互溝通與尊重,一同創造,數十年後,這經典影像紀錄,能再次感動全家人
    </p>
</div>
<app-footer></app-footer>