<!-- <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">

    <ol class="carousel-indicators">
        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"></li>
        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="../../assets/images/homeCarousel/demo1.jpg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
            <img src="../../assets/images/homeCarousel/demo2.jpg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
            <img src="../../assets/images/homeCarousel/demo3.jpg" class="d-block w-100" alt="...">
        </div>
    </div>
</div> -->
<div id="carouselContainer" class="col-md-12 border px-0">
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
            <img src="assets/images/homeCarousel/新生兒2.jpg" class="d-block w-100" alt="...">
        </ng-template>
        <ng-template carouselSlide>
            <img src="assets/images/homeCarousel/孕婦寫真2.jpg" class="d-block w-100" alt="...">
        </ng-template>
        <ng-template carouselSlide>
            <img src="assets/images/homeCarousel/全家福2.jpg" class="d-block w-100" alt="...">
        </ng-template>
        <ng-template carouselSlide>
            <img src="assets/images/homeCarousel/兒童寫真2.jpg" class="d-block w-100" alt="...">
        </ng-template>
        <!-- <ng-template carouselSlide>
            <img src="assets/images/homeCarousel/兒童寫真1.jpg" class="d-block w-100" alt="...">
        </ng-template> -->
        <ng-template carouselSlide>
            <img src="assets/images/homeCarousel/婚紗攝影2.jpg" class="d-block w-100" alt="...">
        </ng-template>
        <ng-template carouselSlide>
            <img src="assets/images/homeCarousel/藝術寫真2.jpg" class="d-block w-100" alt="...">
        </ng-template>
    </owl-carousel-o>
</div>