import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-family',
  templateUrl: './portfolio-family.component.html',
  styleUrls: ['./portfolio-family.component.scss']
})
export class PortfolioFamilyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
