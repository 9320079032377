import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-wedding2',
  templateUrl: './portfolio-wedding2.component.html',
  styleUrls: ['./portfolio-wedding2.component.scss']
})
export class PortfolioWedding2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
