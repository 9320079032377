import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-art',
  templateUrl: './portfolio-art.component.html',
  styleUrls: ['./portfolio-art.component.scss']
})
export class PortfolioArtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
