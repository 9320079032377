import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qanda',
  templateUrl: './qanda.component.html',
  styleUrls: ['./qanda.component.scss']
})
export class QandaComponent implements OnInit {

  constructor() { }
  qAndA = [
    {
      cat: '新生兒、兒童寫真',
      lists: [
        {
          id: 'a01',
          head: 'h01',
          q: 'Q1：多大算新生兒？',
          a: 'A：出生四個月以內都算新生兒，但建議 5-30 天左右拍攝，初生胎毛等痕跡較為明顯，身體也相對柔軟較能配合拍攝動作擺放。'
        },
        {
          id: 'a02',
          head: 'h02',
          q: 'Q2：新生兒只能到府拍攝嗎？月子中心可以嗎？',
          a: 'A：部分月子中心有管控規定，需要請家長先和入住的館方確認，是否能讓攝影團隊進入。'
        },
        {
          id: 'a03',
          head: 'h03',
          q: 'Q3：到府拍攝和攝影棚拍攝，成品會差別很大嗎？我該選哪個？',
          a: 'A：兩者拍攝內容皆一致，道具衣服套數不會因場地改變而減少，因家中採光不一致，團隊會將專業攝影燈具帶至現場；攝影棚除了專業打燈之外，還有自然光線可以拍攝。因為考量到需要帶著寶貝出門，到府的話，爸爸媽媽可以比較不麻煩，建議可以依照方便性做選擇，兩者的拍攝效果攝影師都會盡最大的努力，拍出最好的成品喔！'
        },
        {
          id: 'a04',
          head: 'h04',
          q: 'Q4：方案內容包含全部未修毛片嗎？ ',
          a: 'A：毛片是以加購的方式購買，並無包含至各方案中；可於拍攝完畢後，確認成品再決定是否需要加購喔。'
        },
        {
          id: 'a05',
          head: 'h05',
          q: 'Q5：寶貝吵鬧不配合拍攝，該怎麼辦？',
          a: 'A：拍攝現場有專業保姆證照的安撫師全程協助拍攝，若寶貝吵鬧不停，團隊將暫時中止拍攝，等寶貝安撫情緒後再繼續進行，拍攝過程以寶貝舒服自在為主，不會強破進行。'
        },
        {
          id: 'a06',
          head: 'h06',
          q: 'Q6：PM baby 所使用的器材，以及拍攝道具、服裝是否有定期清潔？',
          a: 'A：團隊外出須將專業攝影燈具、拍攝背景、道具等全數帶至拍攝現場，包含現場消毒作業都需確實到位，具專業保姆證照的安撫師也會隨行協助拍攝，故需加收此費用。'
        },
        {
          id: 'a07',
          head: 'h07',
          q: 'Q7：若選擇到府服務(含月子中心)方案，家中照明不足，會不會影響拍攝？',
          a: 'A：攝影團隊會將專業照明設備帶至現場，使用的燈具是持續燈光，非閃光燈，家長可以放心不會傷到寶貝的眼睛。'
        },
        {
          id: 'a08',
          head: 'h08',
          q: 'Q8：預定好的拍攝日期因突發事情需要改期或取消，需要加收費用嗎？',
          a: 'A：若因不可抗力量，天氣、身體不適等原因延期，不需加收另外費用；工作室在訂金確認入帳後，會優先將您的拍攝日期壓入排程，因故取消則需酌收工本費 $1,000，其餘費用退還。請儘早於兩週前告知取消原因，PM baby 將為您處理退款流程。'
        },
        {
          id: 'a09',
          head: 'h09',
          q: 'Q9：新生兒寫真可以拍攝幾套造型服裝？全部拍攝時間需要多久呢？',
          a: 'A：造型服裝拍攝套數為 2-5 套，視寶貝狀況而定；初生寶寶需要安撫和餵奶等需求，拍攝全程約 1-2 小時。'
        },
        {
          id: 'a10',
          head: 'h10',
          q: 'Q10：攝影師拍攝過程中，我可以側拍或側錄嗎？',
          a: 'A：拍攝寶貝需要專注與安全，現場攝影燈具線材較多，為了安全以及不中斷團隊拍攝進度，現場是不開放側拍或側錄的喔！'
        }
      ]
    },
    {
      cat: '婚紗攝影',
      lists: []
    },
    {
      cat: '其它',
      lists: []
    }

  ]
  ngOnInit(): void {
  }

}
